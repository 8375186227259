<template>
  <transition
    enter-active-class="transition-all duration-200 ease-out"
    leave-active-class="transition-all duration-200 ease-out"
    enter-class="opacity-0 transform -translate-y-2"
    leave-to-class="opacity-0 transform -translate-y-2"
    mode="out-in"
    appear
  >
    <div v-if="show" class="fixed left-0 top-0 z-150 flex w-full justify-center px-4 py-3">
      <AlertComponent :variant="variant">
        <slot />
      </AlertComponent>
    </div>
  </transition>
</template>

<script>
import AlertComponent from '@/Components/AlertComponent.vue'

export default {
  components: {
    AlertComponent,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    variant: {
      type: String,
      default: 'success',
    },
  },
}
</script>
