<template>
  <vSelect
    :model-value="selected"
    class="form-input"
    :placeholder="placeholder"
    :options="filtered"
    :reduce="(option) => option.key"
    :selectable="(option) => option.disabled !== true"
    :clearable="clearable"
    :filterable="false"
    @update:model-value="(newVal) => $emit('update:modelValue', newVal)"
    @search="(query) => (search = query)"
  >
    <template #option="option">
      <div class="flex max-w-full items-center">
        <slot :key="option.key" :label="option.label" :raw="option">
          <div class="h-2.5 truncate">{{ option.label }}</div>
        </slot>
      </div>
    </template>
    <template #selected-option="option">
      <div class="flex max-w-full items-center">
        <slot :key="option.key" :label="option.label" :raw="option">
          <div class="truncate">{{ option.label }}</div>
        </slot>
      </div>
    </template>
    <template #no-options>
      <div class="flex max-w-full items-center">
        <span class="text-grey dark:text-dark-grey">
          {{ noResultsMessage }}
        </span>
      </div>
    </template>
  </vSelect>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    noResultsMessage: {
      type: String,
      default: 'No results',
    },
    options: {
      type: Array,
      required: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],

  data() {
    return {
      search: '',
    }
  },
  computed: {
    selected() {
      if (this.options.find((entry) => entry.key === this.modelValue)) {
        return this.modelValue
      }

      return null
    },
    filtered() {
      if (!this.searchable) {
        return this.options
      }

      return this.options.filter((entry) => entry.label.toString().toLowerCase().includes(this.search.toLowerCase()))
    },
  },
}
</script>
