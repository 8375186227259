<template>
  <div>
    <div
      :class="{
        'pb-3': isLeadMagnetOrOneHundredPercentDiscount,
        'px-0': !checkoutOptions.embed,
        'px-4 pb-3': checkoutOptions.embed,
      }"
    >
      <CheckoutFormHeader v-model="selectedPaymentMethod" :is-busy="isBusy" />
      <div>
        <form @submit.prevent="submit()">
          <fieldset :disabled="isPaying">
            <div>
              <div class="form-group">
                <label v-if="!isLeadMagnetOrOneHundredPercentDiscount" for="email" class="form-label">
                  Email address
                </label>

                <input
                  id="email"
                  ref="email"
                  v-model="form.email"
                  :disabled="!isInitialized"
                  type="text"
                  class="form-input"
                  placeholder="e.g. john@example.com"
                  required
                  :readonly="currentUser && !isTestMode"
                  dusk="checkout-form-email"
                  @blur="saveEmail()"
                />

                <p v-if="form.errors.email" class="form-error">
                  {{ form.errors.email }}
                </p>
              </div>
              <div v-show="selectedPaymentMethod === 'card' && !isLeadMagnetOrOneHundredPercentDiscount">
                <div v-if="isPreview" class="form-group">
                  <label class="form-label">Card details</label>
                  <input type="text" class="form-input" placeholder="XXXX-XXXX-XXXX-4242" />
                </div>

                <div
                  v-else
                  class="mt-3"
                  :class="{
                    'mb-1': stripePaymentType === 'card',
                    'mb-3': stripePaymentType !== 'card',
                  }"
                >
                  <div ref="paymentElement" />

                  <div v-if="stripe && stripe.hasError()" class="stripe-error" dusk="stripe-error">
                    {{ stripe.error.message }}
                  </div>
                </div>
              </div>
              <template v-if="!isLeadMagnetOrOneHundredPercentDiscount">
                <div class="form-group">
                  <label for="name" class="form-label">
                    <template v-if="selectedPaymentMethod === 'card' && stripePaymentType === 'card'">
                      Cardholder name
                    </template>
                    <template v-else>Full name</template>
                  </label>
                  <input
                    id="name"
                    ref="name"
                    v-model="form.name"
                    type="text"
                    class="form-input"
                    placeholder="John More Doe"
                    autocomplete="cc-name"
                    required
                    dusk="checkout-form-name"
                  />
                  <p v-if="form.errors.name" class="form-error">
                    {{ form.errors.name }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="country" class="form-label">Billing address</label>
                  <div ref="country" class="form-input form-input-group">
                    <select
                      id="country"
                      v-model="form.country"
                      class="form-input !rounded-b-none !border-b !border-light-91 focus:border-transparent"
                      dusk="checkout-form-country"
                      :disabled="canUpdateAddress"
                    >
                      <option v-for="country in countries()" :key="country.code" :value="country.code">
                        {{ country.name }}
                      </option>
                    </select>

                    <template v-if="form.country === 'US'">
                      <AddressAutocomplete
                        v-model="form.line1"
                        v-model:city="form.city"
                        v-model:state="form.state"
                        v-model:zip="form.postal_code"
                        :disabled="canUpdateAddress"
                        dusk="checkout-form-line1"
                      />

                      <UsStateDropdown
                        id="state"
                        v-model="form.state"
                        class="border-b rounded-none"
                        dusk="checkout-form-state"
                        :disabled="canUpdateAddress"
                      />
                    </template>

                    <div class="flex">
                      <input
                        v-if="form.country === 'US'"
                        id="city"
                        v-model="form.city"
                        type="text"
                        placeholder="City"
                        class="form-input border-r rounded-br-none rounded-tr-none rounded-tl-none"
                        dusk="checkout-form-city"
                        :disabled="canUpdateAddress"
                      />
                      <input
                        id="postal_code"
                        ref="postal_code"
                        v-model="form.postal_code"
                        type="text"
                        class="form-input !rounded-t-none"
                        :class="{
                          'rounded-bl-none': form.country === 'US',
                        }"
                        :placeholder="postalCodePlaceholder"
                        dusk="checkout-form-postal-code"
                        :disabled="canUpdateAddress"
                      />
                    </div>
                  </div>

                  <p
                    v-if="
                      form.errors.country ||
                      form.errors.postal_code ||
                      form.errors.line1 ||
                      form.errors.city ||
                      form.errors.state
                    "
                    class="form-error flex flex-col break-all"
                  >
                    <span>{{ form.errors.country }}</span>
                    <span>{{ form.errors.line1 }}</span>
                    <span>{{ form.errors.state }}</span>
                    <span>{{ form.errors.city }}</span>
                    <span>{{ form.errors.postal_code }}</span>
                  </p>
                </div>
              </template>
              <div
                v-if="!isLeadMagnetOrOneHundredPercentDiscount && (!checkoutOptions.embed || showTaxId)"
                class="form-group"
              >
                <label for="tax_number" class="form-label flex justify-between">
                  <span>Tax ID number <span class="text-13 font-normal text-grey">(optional)</span></span>

                  <a
                    v-if="canRequestExemption"
                    class="text-lemon-links hover:underline cursor-pointer ml-auto font-normal"
                    :class="{
                      'mr-1': checkoutOptions.embed,
                    }"
                    :href="checkoutHandler.$route('checkout.tax-exempt.redirect', currentStore.url_domain)"
                  >
                    Tax exempt?
                  </a>

                  <a
                    v-if="checkoutOptions.embed"
                    class="text-13 font-normal text-wtf-wildblue hover:text-wtf-majorelle"
                    @click.prevent="showTaxId = false"
                  >
                    Cancel
                  </a>
                </label>
                <ValidatedInput
                  v-model="form.tax_number"
                  :valid="cart.data.tax_number_valid"
                  dusk="checkout-form-tax-number"
                  :disabled="canUpdateAddress"
                />
                <p v-if="form.errors.tax_number" class="form-error">
                  {{ form.errors.tax_number }}
                </p>
              </div>
              <div v-if="canApplyDiscount && (!checkoutOptions.embed || showDiscount)" class="form-group">
                <label for="discount_code" class="form-label flex justify-between">
                  <span>Discount code</span>
                  <a
                    v-if="checkoutOptions.embed"
                    class="text-13 font-normal text-wtf-wildblue hover:text-wtf-majorelle"
                    @click.prevent="showDiscount = false"
                  >
                    Cancel
                  </a>
                </label>
                <InputWithButton
                  id="discount_code"
                  v-model="form.discount_code"
                  button-label="Apply"
                  input-class="uppercase"
                  :hide-when-empty="true"
                  dusk="checkout-form-discount-code"
                  @change="addDiscount()"
                  @apply="addDiscount()"
                />
                <p v-if="form.errors.discount_code" class="form-error">
                  {{ form.errors.discount_code }}
                </p>
              </div>
              <div
                v-if="checkoutOptions.embed && (canApplyDiscount || canApplyTaxId)"
                class="form-group text-center text-13 text-grey"
              >
                <a
                  v-if="canApplyDiscount && !showDiscount"
                  href="#"
                  class="hover:text-wtf-majorelle"
                  @click.prevent="showDiscount = true"
                >
                  Add discount code
                </a>
                <span v-if="canApplyDiscount && !showDiscount && canApplyTaxId && !showTaxId" class="px-1">·</span>
                <a
                  v-if="canApplyTaxId && !showTaxId"
                  href="#"
                  class="hover:text-wtf-majorelle"
                  @click.prevent="showTaxId = true"
                >
                  Add Tax ID number
                </a>
              </div>

              <template v-if="isInitialized">
                <CheckoutFormTrialSummary v-if="isFreeTrial && !isUsageBased" @remove-discount="removeDiscount()" />
                <CheckoutFormOrderSummary
                  v-else-if="!isLeadMagnetOrOneHundredPercentDiscount && !isUsageBased && !checkoutOptions.embed"
                  @remove-discount="removeDiscount()"
                />
                <CheckoutFormUsageBasedSummary
                  v-if="isUsageBased && !checkoutOptions.embed"
                  @remove-discount="removeDiscount()"
                />
              </template>

              <p v-if="submitError" class="form-error mb-3 mt-0">
                {{ submitError }}
              </p>
              <CheckoutFormSubmitButton
                v-show="selectedPaymentMethod === 'card' || isLeadMagnetOrOneHundredPercentDiscount"
                :is-initialized="isInitialized"
                :is-paying="isPaying"
                :disabled="
                  isBusy ||
                  (selectedPaymentMethod === 'card' && !isLeadMagnetOrOneHundredPercentDiscount && !stripeCompleted)
                "
                @click="submit()"
              />
            </div>
          </fieldset>
        </form>
      </div>
      <div v-if="!isLeadMagnetOrOneHundredPercentDiscount" v-show="selectedPaymentMethod === 'paypal'">
        <PayPalSubscriptionButton
          v-if="isSubscription"
          :selected-payment-method="selectedPaymentMethod"
          :store="store"
          :cart="cart"
          :country="form.country"
          :postal-code="form.postal_code"
          :disabled="isBusy"
          :is-preview="isPreview"
          dusk="checkout-form-submit-paypal"
          @working="(working) => (paypalWorking = working)"
          @success="submitPayPalCheckout"
        />
        <PayPalButton
          v-else
          :store="store"
          :cart="cart"
          :country="form.country"
          :postal-code="form.postal_code"
          :disabled="isBusy"
          :is-preview="isPreview"
          dusk="checkout-form-submit-paypal"
          @working="(working) => (paypalWorking = working)"
          @success="submitPayPalCheckout"
        />
      </div>
      <CheckoutFormSubcopy
        v-if="isInitialized"
        :selected-payment-method="selectedPaymentMethod"
        :country="form.country"
        @remove-discount="removeDiscount()"
      />
    </div>
    <CheckoutFormSecureAndEncrypted v-if="!isSubscription && !isLeadMagnetOrOneHundredPercentDiscount" />
    <CheckoutFooter v-if="!checkoutOptions.embed" class="mt-3" />
  </div>
</template>

<script>
import { useForm } from '@inertiajs/inertia-vue3'
import axios from 'axios'
import { formatNumber } from 'chart.js/helpers'
import _ from 'lodash'

import AddressAutocomplete from '@/Components/Forms/AddressAutocomplete.vue'
import InputWithButton from '@/Components/Forms/InputWithButton.vue'
import UsStateDropdown from '@/Components/Forms/UsStateDropdown.vue'
import ValidatedInput from '@/Components/Forms/ValidatedInput.vue'
import Countries from '@/Mixins/Countries.js'
import CheckoutFooter from '@/Pages/Web/Components/Checkout/CheckoutFooter.vue'
import PayPalButton from '@/Pages/Web/Components/Checkout/PayPalButton.vue'
import PayPalSubscriptionButton from '@/Pages/Web/Components/Checkout/PayPalSubscriptionButton.vue'

import CheckoutFormHeader from './CheckoutForm/CheckoutFormHeader.vue'
import CheckoutFormOrderSummary from './CheckoutForm/CheckoutFormOrderSummary.vue'
import CheckoutFormSecureAndEncrypted from './CheckoutForm/CheckoutFormSecureAndEncrypted.vue'
import CheckoutFormSubcopy from './CheckoutForm/CheckoutFormSubcopy.vue'
import CheckoutFormSubmitButton from './CheckoutForm/CheckoutFormSubmitButton.vue'
import CheckoutFormTrialSummary from './CheckoutForm/CheckoutFormTrialSummary.vue'
import CheckoutFormUsageBasedSummary from './CheckoutForm/CheckoutFormUsageBasedSummary.vue'
import useCheckout from './useCheckout.js'
import useStripe from './useStripe.js'

export default {
  components: {
    UsStateDropdown,
    AddressAutocomplete,
    CheckoutFooter,
    InputWithButton,
    PayPalButton,
    PayPalSubscriptionButton,
    ValidatedInput,
    CheckoutFormHeader,
    CheckoutFormSubcopy,
    CheckoutFormSecureAndEncrypted,
    CheckoutFormTrialSummary,
    CheckoutFormOrderSummary,
    CheckoutFormUsageBasedSummary,
    CheckoutFormSubmitButton,
  },
  mixins: [Countries],
  inject: ['isApi', 'isInitialized', 'billingAddress'],

  props: {
    store: {
      type: Object,
      required: true,
    },
    cart: {
      type: Object,
      required: true,
    },
    stripeKey: {
      type: String,
      required: true,
    },
    checkoutOptions: {
      type: Object,
      required: true,
    },
    hasValidDiscounts: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    submitErrors: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['submit', 'payment-processing'],

  data() {
    return {
      checkoutHandler: null,
      stripe: null,
      selectedPaymentMethod: 'card',
      showDiscount: false,
      showTaxId: false,
      paypalWorking: false,
      form: useForm({
        email: this.cart.user_email,
        name: '',
        country: this.cart.country ?? this.billingAddress?.country ?? 'US',
        postal_code: '',
        tax_number: '',
        discount_code: '',
        line1: this.billingAddress?.line1 ?? '',
        city: this.billingAddress?.city ?? '',
        state: this.billingAddress?.state ?? '',
      }),
      hasSubmitted: false,
      formCompleted: false,
      authorizeWorking: false,
      validateWorking: false,
    }
  },

  computed: {
    cartItem() {
      return this.cart.items[0]
    },
    priceModel() {
      return this.cartItem.price_model
    },
    isBusy() {
      const needsTaxValidation = _.get(this.cart, 'data.needs_tax_validation')

      return (
        needsTaxValidation === true ||
        this.disabled ||
        this.form.processing ||
        this.authorizeWorking ||
        this.checkoutHandler.working ||
        this.paypalWorking ||
        this.hasSubmitted ||
        (this.stripe && this.stripe.processing)
      )
    },
    isPaying() {
      return (
        this.disabled ||
        this.authorizeWorking ||
        this.validateWorking ||
        this.paypalWorking ||
        (this.stripe && this.stripe.processing)
      )
    },
    isSubscription() {
      return this.priceModel.category === 'subscription'
    },
    isFreeTrial() {
      return this.isSubscription && this.cart.has_free_trial
    },
    isUsageBased() {
      return this.isSubscription && this.priceModel.usage_aggregation !== null
    },
    isLeadMagnetOrOneHundredPercentDiscount() {
      return this.cart.is_lead_magnet_or_one_hundred_percent_discount
    },
    postalCodePlaceholder() {
      if (this.form.country === 'US') {
        return 'ZIP'
      }
      if (['GB', 'AU'].includes(this.form.country)) {
        return 'Postcode'
      }

      return 'Postal Code'
    },
    canApplyTaxId() {
      return !this.isLeadMagnetOrOneHundredPercentDiscount
    },
    canApplyDiscount() {
      return (
        this.hasValidDiscounts &&
        !this.activeDiscount &&
        !this.isLeadMagnetOrOneHundredPercentDiscount &&
        this.checkoutOptions.discount
      )
    },
    activeDiscount() {
      if (this.cart.discount) {
        return this.cart.discount
      }

      return null
    },
    showState() {
      return ['US', 'CA'].includes(this.form.country)
    },
    submitError() {
      if (_.isEmpty(this.submitErrors)) {
        return null
      }
      if (Object.prototype.hasOwnProperty.call(this.submitErrors, 'purchase_attempt')) {
        return null
      }

      const firstError = Object.values(this.submitErrors)[0]

      return Array.isArray(firstError) ? firstError[0] : firstError
    },
    stripePaymentType() {
      return this.stripe?.paymentType || 'card'
    },
    stripeCompleted() {
      return this.stripe && this.stripe.isComplete
    },
    paymentService() {
      return this.selectedPaymentMethod === 'card' ? 'stripe' : 'paypal'
    },
    canRequestExemption() {
      return (
        this.form.email &&
        this.cart.tax_status !== 'exempt' &&
        this.form.tax_number &&
        this.form.country &&
        this.form.line1 &&
        this.form.city &&
        this.form.postal_code &&
        this.form.state
      )
    },
    canUpdateAddress() {
      const requestedExemption = _.get(this.cart, 'data.requested_exemption')

      return !!(requestedExemption && requestedExemption === true)
    },
  },

  watch: {
    'cart.total_in_usd': async function (newVal) {
      if (newVal > 0 && this.isInitialized) {
        if (this.stripe && this.stripe.elements) {
          await this.stripe.elements.update({
            mode: this.isSubscription ? 'subscription' : 'payment',
            amount: newVal,
          })
        } else {
          await this.setupStripe()
        }
      }
    },
    async isSubscription(newVal) {
      const mode = newVal ? 'subscription' : 'payment'
      if (this.isInitialized && this.stripe && this.stripe.elements && this.stripe.elements.mode !== mode) {
        await this.stripe.elements.update({
          mode: mode,
        })
      }
    },
    form: {
      deep: true,
      immediate: false,
      handler() {
        this.formCompleted = !!(this.form.email && this.form.name && this.form.country && this.form.postal_code)
      },
    },
    'form.email': function () {
      this.form.clearErrors('email')
    },
    'form.name': function () {
      this.form.clearErrors('name')
    },
    'form.line1': function () {
      this.form.clearErrors('line1')
    },
    'form.city': function () {
      this.form.clearErrors('city')
    },
    'form.state': function () {
      this.form.clearErrors('state')
    },
    'form.country': function () {
      this.form.postal_code = _.get(this.cart, 'data.checkout_data.billing_address.zip', '')
      this.form.clearErrors('line1')
      this.form.clearErrors('state')
      this.form.clearErrors('city')
      this.form.clearErrors('postal_code')
    },
    'form.tax_number': function () {
      this.form.clearErrors('tax_number')
    },
    'form.discount_code': function () {
      this.form.clearErrors('discount_code')
    },
    'form.postal_code': function () {
      this.form.clearErrors('postal_code')
    },
    isPaying(newVal) {
      if (this.stripe) {
        this.stripe.setReadOnly(newVal)
      }
    },
    submitErrors: {
      handler: function (newVal) {
        if (!_.isEmpty(newVal)) {
          this.hasSubmitted = false
        }
      },
      deep: true,
    },
    isInitialized() {
      this.initialize()
    },
  },

  created() {
    this.initialize()
  },

  mounted() {
    this.setupStripe()

    const needsTaxValidation = _.get(this.cart, 'data.needs_tax_validation')

    const checkoutHasZip = _.get(this.cart, 'checkout.checkout_data.billing_address.zip')
    const checkoutHasCountry = _.get(this.cart, 'checkout.checkout_data.billing_address.country')

    if (needsTaxValidation || (checkoutHasZip && checkoutHasCountry)) {
      this.calculateTax().catch()
    }

    this.$watch(
      (vm) => {
        let watcherExpression = [vm.form.country, vm.form.postal_code, vm.form.tax_number]

        if (vm.form.country === 'US') {
          watcherExpression.push(vm.form.line1, vm.form.city, vm.form.state)
        }

        return watcherExpression
      },
      _.debounce(() => {
        this.calculateTax().catch()
      }, 500),
      { deep: true },
    )
  },

  methods: {
    initialize() {
      this.checkoutHandler = useCheckout(this.store.url_domain, this.cart.id, this.isApi, this.isPreview)

      if (this.currentUser) {
        this.form.email = this.currentUser.email
        this.form.name = this.currentUser.name
      } else {
        this.form.email = this.getOr(this.cart, 'data.checkout_data.email', this.cart.user_email)
        this.form.name = _.get(this.cart, 'data.checkout_data.name', '')
      }

      this.form.country = this.getOr(this.cart, 'data.checkout_data.billing_address.country', this.form.country)
      this.form.tax_number = _.get(this.cart, 'data.checkout_data.tax_number', '')
      this.form.discount_code = _.get(this.cart, 'data.checkout_data.discount_code', '')
      this.form.postal_code = _.get(this.cart, 'data.checkout_data.billing_address.zip', '')

      if (this.stripe && !this.stripe.isReturnFromStripe()) {
        let p = Promise.resolve()
        if (this.form.country && this.form.postal_code) {
          p = p.then(() => this.calculateTax().catch())
        }
        if (this.form.discount_code) {
          p.then(() => this.addDiscount())
        }
      }
    },
    formatNumber,
    async setupStripe() {
      if (this.isPreview) {
        return
      }

      if (!this.$refs.paymentElement) {
        return
      }

      if (this.isLeadMagnetOrOneHundredPercentDiscount) {
        return
      }

      if (!this.stripe) {
        this.stripe = useStripe(this.stripeKey)
      }

      if (!this.stripe.elements) {
        await this.stripe.createPaymentElement(
          this.$refs.paymentElement,
          this.isSubscription ? 'subscription' : 'payment',
          this.cart.total_in_usd,
          'USD',
          this.form.email,
          this.store.apple_pay_enabled,
          this.store.google_pay_enabled,
        )
      }

      if (this.stripe.isReturnFromStripe()) {
        this.stripe.fetchIntentFromClientSecret().then(this.handleStripeIntent)
      }
    },
    saveEmail() {
      if (!this.isInitialized) {
        return
      }

      return this.checkoutHandler
        .saveEmail(this.form.email)
        .then(() => {
          if (this.stripe.paymentElement) {
            this.stripe.paymentElement.update({
              defaultValues: {
                billingDetails: {
                  email: this.form.email,
                },
              },
            })
          }
        })
        .catch((errors) => {
          this.form.errors = { ...this.form.errors, ...errors }
        })
    },
    addDiscount() {
      if (!this.canApplyDiscount) {
        return
      }

      return this.checkoutHandler
        .addDiscount(this.form.discount_code, this.paymentService)
        .then(() => {
          this.eventHub.emit('discount-added')
          this.eventHub.emit('cart-updated')
          
          this.form.clearErrors('discount_code')

          window.parent.postMessage(
            {
              event: 'Checkout.ApplyDiscount',
              data: {
                cart: JSON.parse(JSON.stringify(this.cart)),
              },
            },
            '*',
          )
        })
        .catch((errors) => {
          this.form.errors = { ...this.form.errors, ...errors }
        })
    },
    removeDiscount() {
      // Make a copy of cart, so it still has the discount code in memory.
      const previousCart = { ...this.cart }

      return this.checkoutHandler
        .removeDiscount()
        .then(() => {
          this.eventHub.emit('cart-updated')
          window.parent.postMessage(
            {
              event: 'Checkout.RemoveDiscount',
              data: {
                cart: JSON.parse(JSON.stringify(previousCart)),
              },
            },
            '*',
          )
          this.eventHub.emit('discount-removed', previousCart)
        })
        .catch((errors) => {
          this.form.errors = { ...this.form.errors, ...errors }
        })
    },
    calculateTax() {
      return this.checkoutHandler.calculateTax(this.form).catch((errors) => {
        this.form.errors = { ...this.form.errors, ...errors }
      })
    },
    submit() {
      if (!this.isInitialized) {
        return
      }

      if (this.isPreview) {
        return
      }
      if (this.isBusy) {
        return
      }

      if (this.stripe) {
        this.stripe.clearError()
      }

      if (this.isLeadMagnetOrOneHundredPercentDiscount) {
        this.validate()
          .then(this.submitCheckout)
          .catch(() => {})

        return
      }

      this.stripe.elements.submit().then((result) => {
        if (result.error) {
          this.stripe.error = {
            message: result.error.message,
          }
        } else {
          this.validate()
            .then(this.authorizePayment)
            .then(this.confirm)
            .then(this.handleStripeIntent)
            .catch(() => {})
        }
      })
    },
    validateForm() {
      const fields = {
        email: 'email',
        name: 'name',
        country: 'country',
        postal_code: 'ZIP',
      }

      if (this.isLeadMagnetOrOneHundredPercentDiscount) {
        delete fields.name
        delete fields.country
        delete fields.postal_code
      } else if (this.form.country === 'US') {
        Object.assign(fields, {
          state: 'State',
          city: 'City',
          line1: 'Address',
        })
      }

      this.form.clearErrors(Object.keys(fields))

      const invalidFields = Object.entries(fields).filter(([field]) => !this.form[field])

      const errors = invalidFields.reduce((value, [field, label]) => {
        value[field] = `The ${label} field is required`

        return value
      }, {})

      this.form.errors = { ...this.form.errors, ...errors }

      this.scrollToFormError()

      return !invalidFields.length
    },
    validate() {
      this.validateWorking = true

      this.form.clearErrors('postal_code')

      return new Promise((resolve, reject) => {
        if (!this.validateForm()) {
          this.validateWorking = false

          reject()

          return
        }

        this.checkoutHandler
          .validate(
            {
              ...this.form.data(),
              service: this.selectedPaymentMethod === 'card' ? 'stripe' : 'paypal',
            },
            this.paymentService,
          )
          .then(() => resolve())
          .catch((errors) => {
            this.form.errors = { ...this.form.errors, ...errors }
            reject(errors)
          })
          .finally(() => {
            this.validateWorking = false
          })
      })
    },
    authorizePayment() {
      this.authorizeWorking = true

      return new Promise((resolve, reject) => {
        return axios
          .post(this.checkoutHandler.$route('checkout.authorize'))
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            const errors = Object.fromEntries(
              Object.entries(error.response.data.errors).map(([key, value]) => [key, value[0]]),
            )

            this.form.errors = { ...this.form.errors, ...errors }

            reject(errors)
          })
          .finally(() => {
            this.authorizeWorking = false
          })
      })
    },
    confirm(authorizePayload) {
      let billingDetails = {
        email: this.form.email.trim(),
        address: {
          country: this.form.country,
          postal_code: this.form.postal_code,
        },
      }

      if (this.form.country === 'US') {
        billingDetails.address.line1 = this.form.line1.trim()
        billingDetails.address.city = this.form.city.trim()
        billingDetails.address.state = this.form.state.trim()
      }

      if (this.form.name.trim()) {
        billingDetails.name = this.form.name.trim()
      }

      const confirmIntent = authorizePayload.type === 'setup' ? this.stripe.confirmSetup : this.stripe.confirmPayment

      return confirmIntent(authorizePayload.client_secret, this.cart.checkout_url, billingDetails)
    },
    handleStripeIntent(intent) {
      if (!intent) {
        return
      }

      if (intent.status === 'processing') {
        this.$emit('payment-processing')
      } else if (['succeeded', 'requires_capture'].includes(intent.status)) {
        this.submitCheckout()
      } else if (intent.status === 'requires_payment_method') {
        this.stripe.error = {
          message: 'Payment failed. Please try another payment method.',
        }
      } else {
        this.stripe.error = {
          message: 'Something went wrong. Please try again.',
        }
      }
    },
    submitCheckout() {
      if (this.isPreview || this.hasSubmitted) {
        return
      }

      this.$emit('submit', {
        service: 'stripe',
        email: this.form.email,
        name: this.form.name,
      })

      this.hasSubmitted = true
    },
    submitPayPalCheckout() {
      if (this.isPreview || this.hasSubmitted) {
        return
      }

      this.$emit('submit', {
        service: 'paypal',
        email: this.form.email,
        name: this.form.name,
      })

      this.hasSubmitted = true
    },
    requestTaxExemptCertificate() {
      return this.checkoutHandler.requestTaxExemptLink(this.form)
    },
  },
}
</script>
