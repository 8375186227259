<template>
  <div
    class="ls-checkout ls-checkout-embed theme-area-overlay relative flex min-h-screen items-center justify-center px-2"
  >
    <div class="bg absolute inset-0 bg-lemon-background opacity-90" dusk="checkout-embed-background" />
    <button
      v-if="!isPreview"
      class="fixed right-0 top-0 z-150 mr-3 h-5 w-5 cursor-pointer rounded-full bg-light-97 p-1 focus:outline-none"
      :class="{
        'translate-y-9 transform': isTestMode,
        'mt-10': checkoutMorExperimentEnabled,
        'mt-2': !checkoutMorExperimentEnabled,
      }"
      @click="close()"
    >
      <SvgVue icon="Cross" class="h-3 w-3 stroke-current" />
    </button>
    <div class="relative z-140 max-w-50 py-15">
      <div
        v-if="!showSuccess && !isFulfilling && !hasFailed && purchaseAttempt === 'allow' && !paymentIsProcessing"
        class="rounded bg-white shadow-menu"
        :class="{
          'pt-1': !showMedia && !showLogo,
        }"
      >
        <CheckoutProductMedia
          v-if="showMedia"
          :product="product"
          :is-embed="true"
          :is-preview="isPreview"
          dusk="checkout-product-media"
        />
        <div v-if="showLogo" class="h-5">
          <div class="mx-auto h-10 w-10 -translate-y-5 transform rounded-full bg-white" dusk="checkout-logo">
            <StoreAvatar :store="store" />
          </div>
        </div>
        <h1
          class="mt-2 px-5 text-center text-24 font-medium"
          :class="{
            'mb-1': showDescription,
            'mb-3': !showDescription,
            'mt-3': !showLogo && showMedia,
          }"
          dusk="checkout-product-name"
        >
          <span>{{ product.name }}</span>
          <span v-if="!showVariants && isMultiVariant">&nbsp;({{ cartItem.variant.name }})</span>
        </h1>
        <div v-if="showDescription" class="mb-3 mt-1 px-5 text-center">
          <div class="formatted-html" dusk="checkout-product-description" v-html="product.description" />
        </div>
        <div
          v-if="showVariants || selectedVariant.priceModel.category === 'pwyw'"
          class="border-t border-light-95 px-4 py-2"
        >
          <div v-if="showVariants">
            <label
              v-for="(variant, index) in publishedVariants"
              :key="variant.id"
              class="flex cursor-pointer items-center rounded py-1 pl-1.5 pr-2 hover:bg-light-97"
              :class="{
                'text-grey hover:text-body': variant.id !== selectedVariant.id,
                'bg-light-97 font-medium text-body': variant.id === selectedVariant.id,
              }"
              :dusk="`checkout-variant-${variant.id}`"
            >
              <input
                type="radio"
                class="form-radio mr-1 flex-shrink-0"
                :checked="variant.id === selectedVariant.id"
                :disabled="disabled || !isInitialized"
                @click="selectVariant(variant)"
              />
              <span class="truncate">
                {{ variant.name }}
                <span v-if="cartItem.quantity > 1" class="font-normal text-wedges-gray">
                  (x{{ cartItem.quantity }})
                </span>
              </span>
              <span class="ml-auto">
                <span v-if="variant.priceModel.from_price"> {{ variant.priceModel.from_price }}+ </span>
                <span v-else>
                  <template v-if="variant.priceModel.category === 'pwyw'">
                    {{ formatCurrency(prices[index], cart.currency) }}
                  </template>
                  <template v-else>
                    {{ formatCurrency(variant.priceModel.unit_price, cart.currency) }}
                  </template>
                </span>
              </span>
            </label>
          </div>
          <div
            v-if="selectedVariant.priceModel.category === 'pwyw'"
            :class="{ 'mt-1': showVariants, 'my-1': !showVariants }"
          >
            <PriceInput
              v-model="prices[selectedVariantIndex]"
              class="flex-grow"
              :disabled="disabled || !isInitialized"
              :dusk="`checkout-pwyw-price-input-${selectedVariantIndex}`"
              @blur="emitChange"
            >
              <template #prefix> Suggest a price:</template>
            </PriceInput>
            <p v-if="pwywError" class="form-error">
              {{ pwywError }}
            </p>
          </div>
        </div>
        <div class="border-t border-light-95 p-0 pt-3">
          <CheckoutForm
            :store="store"
            :cart="cart"
            :stripe-key="stripeKey"
            :checkout-options="checkoutOptions"
            :has-valid-discounts="hasValidDiscounts"
            :disabled="disabled"
            :is-preview="isPreview"
            :submit-errors="submitErrors"
            @submit="(payload) => $emit('submit', payload)"
            @payment-processing="paymentIsProcessing = true"
          />
        </div>
      </div>
      <div v-else class="rounded bg-white p-4 text-center shadow-menu">
        <CheckoutProcessing
          v-if="purchaseAttempt === 'review' || purchaseAttempt === 'processing' || paymentIsProcessing"
        />
        <CheckoutBlocked v-else-if="purchaseAttempt === 'block'" />
        <CheckoutFailed v-else-if="hasFailed" :cart="cart" :checkout-response="checkoutResponse" />
        <div v-else-if="showSuccess || isFulfilling">
          <div class="mx-auto mb-3 w-6">
            <svg class="h-6 w-6" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="24" cy="24" r="24" fill="var(--lemon-button)" />
              <path
                d="M32.5 17.5L20.8125 30.5L15.5 25.5"
                stroke="var(--lemon-button-text)"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <CheckoutSuccess
            :is-embed="true"
            :is-fulfilling="isFulfilling"
            :button-url="buttonUrl"
            :button-text="buttonText"
            :is-preview="isPreview"
            :product="product"
          />
        </div>
      </div>
      <CheckoutFooter
        :is-embed="true"
        :class="{
          'mt-4': !checkoutMorExperimentEnabled,
          'mt-3 px-4': checkoutMorExperimentEnabled,
        }"
      />
    </div>
  </div>
</template>

<script>
import PriceInput from '@/Components/Forms/PriceInput.vue'
import StoreAvatar from '@/Components/StoreAvatar.vue'
import SvgVue from '@/Components/SvgVue.vue'
import CheckoutBlocked from '@/Pages/Web/Components/Checkout/CheckoutBlocked.vue'
import CheckoutFailed from '@/Pages/Web/Components/Checkout/CheckoutFailed.vue'
import CheckoutFooter from '@/Pages/Web/Components/Checkout/CheckoutFooter.vue'
import CheckoutForm from '@/Pages/Web/Components/Checkout/CheckoutForm.vue'
import CheckoutProcessing from '@/Pages/Web/Components/Checkout/CheckoutProcessing.vue'
import CheckoutProductMedia from '@/Pages/Web/Components/Checkout/CheckoutProductMedia.vue'
import CheckoutSuccess from '@/Pages/Web/Components/Checkout/CheckoutSuccess.vue'
import { getGoogleAnalyticsItemPayload } from '@/Util/Tracking.js'

export default {
  components: {
    CheckoutFailed,
    CheckoutBlocked,
    CheckoutFooter,
    CheckoutForm,
    CheckoutProcessing,
    CheckoutProductMedia,
    CheckoutSuccess,
    PriceInput,
    StoreAvatar,
    SvgVue,
  },

  inject: ['isInitialized', 'isApi', 'checkoutMorExperimentEnabled'],

  props: {
    store: {
      type: Object,
      required: true,
    },
    cart: {
      type: Object,
      required: true,
    },
    stripeKey: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    variants: {
      type: Array,
      required: true,
    },
    isMultiVariant: {
      type: Boolean,
      default: false,
    },
    checkoutOptions: {
      type: Object,
      required: true,
    },
    hasValidDiscounts: {
      type: Boolean,
      required: true,
    },
    checkoutResponse: {
      type: Object,
      default: () => ({}),
    },
    isFulfilling: {
      type: Boolean,
      default: false,
    },
    hasFailed: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showSuccess: {
      type: Boolean,
      default: false,
    },
    buttonUrl: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    submitErrors: {
      type: Object,
      default: () => {},
    },
    purchaseAttempt: {
      type: String,
      default: 'allow',
    },
    hasThemeOverrides: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'submit', 'change', 'payment-processing'],

  data() {
    return {
      selectedVariant: null,
      prices: [],
      pwywError: null,
      paymentIsProcessing: false,
    }
  },

  computed: {
    cartItem() {
      return this.cart.items[0]
    },
    selectedVariantIndex() {
      return this.variants.findIndex((variant) => variant.id === this.selectedVariant.id)
    },
    showMedia() {
      return this.checkoutOptions.media && this.product.media.length
    },
    showLogo() {
      return this.checkoutOptions.logo
    },
    showDescription() {
      return !this.descriptionIsEmpty(this.product.description) && this.checkoutOptions.desc
    },
    publishedVariants() {
      let published = this.variants.filter((variant) => variant.status === 'published')

      if (published.length) {
        return published
      }

      return [this.variants[0]]
    },
    showVariants() {
      return this.publishedVariants.length > 1
    },
  },

  watch: {
    cartItem() {
      this.setSelectedVariantFromCartItem()
      this.setPrices()
    },
    selectedVariant(newVal, oldVal) {
      if (oldVal && newVal.id !== oldVal.id) {
        this.pwywError = null
        this.emitChange()
      }
    },
    variants() {
      this.setPrices()
    },
  },

  created() {
    this.setSelectedVariantFromCartItem()
    this.setPrices()
  },

  mounted() {
    window.parent.postMessage('mounted', '*')

    window.parent.postMessage(
      {
        event: 'Checkout.ViewCart',
        data: {
          cart: JSON.parse(JSON.stringify(this.cart)),
          product: JSON.parse(JSON.stringify(this.product)),
        },
      },
      '*',
    )

    window.parent.postMessage(
      {
        event: 'GA.ViewCart',
        data: JSON.parse(
          JSON.stringify({
            currency: this.cart.currency,
            value: this.cart.total / 100,
            items: [getGoogleAnalyticsItemPayload(this.cart, this.product.name)],
          }),
        ),
      },
      '*',
    )
  },

  methods: {
    close() {
      window.parent.postMessage('close', '*')
      this.$emit('close')
    },
    setSelectedVariantFromCartItem() {
      this.selectedVariant = this.variants.find((variant) => variant.id === this.cartItem.variant_id)
    },
    setPrices() {
      this.prices = this.publishedVariants.map((variant) => {
        if (variant.id === this.cartItem.variant.id) {
          return this.cartItem.price
        }
        if (variant.pay_what_you_want) {
          return variant.priceModel.suggested_price
        }

        return variant.priceModel.unit_price
      })
    },
    descriptionIsEmpty(description) {
      return !description || description === '<p></p>'
    },
    selectVariant(variant) {
      if (this.disabled || !this.isInitialized) {
        return
      }

      this.selectedVariant = variant
    },
    emitChange() {
      const index = this.publishedVariants.findIndex((variant) => variant.id === this.selectedVariant.id)
      const price = this.prices[index]

      if (this.selectedVariant.priceModel.category === 'pwyw' && price < this.selectedVariant.priceModel.min_price) {
        this.pwywError = `The minimum price is ${this.formatCurrency(this.selectedVariant.priceModel.min_price, this.cart.currency)}`
        return
      } else {
        this.pwywError = null
      }

      this.$emit('change', {
        cart_item_id: this.cartItem.id,
        variant_id: this.selectedVariant.id,
        price: price,
      })
    },
  },
}
</script>
